.ext-image {
  /* width: 100%;
  max-width: 250px;
  min-width: 100%; */
  visibility: visible;
  /* height: 455px; */
  box-shadow: 0 3px 5px rgb(0 0 0 / 75%);
  margin-bottom: 10px;
  height: 400px;
  width: 260px;
}

.landing-row-main-div {
  padding-top: 3rem;
}
.mob-ad-only {
  display: none;
}
@media (max-width: 700px) {
  .ext-image {
    width: 200px !important;
    max-width: 200px !important;
    min-width: 150px !important;
    max-height: 312px;
    min-height: 250px;
  }
  .landing-row-main-div {
    padding-top: 1rem;
  }
  .mob-ad-only {
    display: flex;
  }
  .mob-ad-only-header {
    display: flex;
  }
}
.landing-ad {
  width: 300px;
  height: 250px;
}
.ext-image-nbt {
  width: 100%;
  max-width: 220px;
  border: 0.5px solid #eee;
  max-height: 265px;
}
@media (max-width: 767px) {
  .landing-ad {
    width: -webkit-fill-available;
    height: auto;
  }
}

.top-banner-container-lp {
  text-align: center;
  justify-content: center;
  display: flex;
}
.toi-label-span {
  display: table;
  padding: 9px 14px;
  border: 1px solid #dddddd;
  border-width: 1px 1px 0 1px;
  border-radius: 0.33em 0.33em 0 0;
  font-size: 1.33rem;
  margin-bottom: 0;
}

.chaoice-inner {
  padding-left: 20px !important;
  display: inline-block;
  text-align: left;
  vertical-align: top;
  width: 100%;
  background-color: #f9f9f9;
  padding: 7.5px 7.5px 3.75px;
  border: 1px solid #ddd;
  border-radius: 2.5px;
  font-size: 1.33rem;
  min-height: 44px;
  overflow: hidden;
  cursor: pointer;
  color: black;
}

.chaoice-inner:focus {
  background-color: #f9f9f9 !important;
  color: black !important;
  box-shadow: none !important;
}
.chaoice-inner:hover {
  background-color: #f9f9f9 !important;
  color: black !important;
  box-shadow: none !important;
}

.toi-links {
  text-align: center;
}
.toi-links > * {
  display: inline-block;
}
.footer {
  margin-top: 1.5rem;
}
.footer [href] {
  color: black;
  font-size: 16px;
}

.toi-sep::before {
  content: " | ";
}
.landing-bottom-ad {
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;
}

.toi-submit-anchor {
  display: table;
  margin: auto;
  padding: 0.8em 3em;
  border-radius: 4px;
  font-size: 1em;
  font-weight: bold;
  color: white;
  background: #F2923D;
  text-decoration: none;
}
.toi-submit-anchor:hover {
  color: white;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .chaoice-inner {
    padding-left: 10px !important;

    font-size: 13px;
    min-height: 38px;
  }
  .toi-label-span {
    display: table;
    padding: 2px 10px;
    border: 1px solid #dddddd;
    border-width: 1px 1px 0 1px;
    border-radius: 0.33em 0.33em 0 0;
    font-size: 1rem !important;
    margin-bottom: 0;
  }

  .toi-submit-anchor {
    padding: 0.5em 2.5em;
  }
}

.landing-pub-col {
  align-self: center !important;
}
.not-ipad {
  display: none;
}
@media (min-width: 1200px) {
  .not-ipad {
    display: block;
  }
}
.ipad-1024 {
  display: none;
}
/* @media (min-width: 1200px) {
  .ipad-1024 {
    display: block;
  }
} */
.toi-submit-control {
  margin-top: 1rem;
}
.form-group {
  padding: 0.5rem 0;
}
.toi-copyright {
  margin-top: 0.75rem;
  opacity: 0.5;
  text-align: center;
}
.dropdown-landing {
  width: -webkit-fill-available !important;
}
.dropdown-menu {
  width: -webkit-fill-available !important;
}
#phone-view-wrap {
  display: flex;
  flex-wrap: wrap-reverse !important;
}

.top-ad-landing {
  display: flex !important;
    justify-content: center;
}
.top-ad-landing-mob {
  display: none;
    justify-content: center;
}
@media (max-width: 700px) {
  .top-ad-landing-mob {
    display: flex !important;
    justify-content: center;
    height: 60px;
  }
}

.header-card-landing {
  display: contents;
}
.landing-cotainer-scroll {
  transform-origin: top left;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 4px 0px;
  padding: 0px;
  width: auto;
}
.landing-cotainer-scroll ::-webkit-scrollbar {
  width: 16px;
  /* width of the entire scrollbar */
}
.landing-cotainer-scroll ::-webkit-scrollbar-track {
  background: #ccc; /* color of the tracking area */
  border-right: 3px solid white;
  border-left: 3px solid white;
  border-top-right-radius: 22px;
  border-top-left-radius: 22px;
  border-bottom-right-radius: 22px;
  border-bottom-left-radius: 22px;
}
.landing-cotainer-scroll ::-webkit-scrollbar-thumb {
  background-color: rgb(134, 133, 133); /* color of the scroll thumb */
  border-right: 3px solid white; /* creates padding around scroll thumb */
  border-left: 3px solid white; /* creates padding around scroll thumb */
  border-top-right-radius: 22px;
  border-top-left-radius: 22px;
  border-bottom-right-radius: 22px;
  border-bottom-left-radius: 22px;
}
.border-landing {
  border-bottom: 3px solid #F2923D;
}

@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */
  .border-landing {
    border-bottom-color: #F2923D;
    border-bottom-width: 7px;
    border-bottom-style: solid;
  }
}
.choices[data-type*="select-one"]:after {
  content: "";
  height: 0;
  width: 0;
  border-style: solid;
  border-color: #333 transparent transparent;
  border-width: 5px;
  position: absolute;
  right: 11.5px;
  top: 50%;
  margin-top: -2.5px;
  pointer-events: none;
}
.choices__list--dropdown {
  display: none;
  z-index: 1;
  position: absolute;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ddd;
  top: 100%;
  margin-top: -1px;
  border-bottom-left-radius: 2.5px;
  border-bottom-right-radius: 2.5px;
  overflow: hidden;
  word-break: break-all;
}
.dropdown-toggle::after {
  display: inline-block !important;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.centre-ipad {
  padding-top: 3rem;
}
@media (max-width: 1024px) {
  .centre-ipad {
    justify-content: center;
  }
}
@media (max-width: 700px) {
  .centre-ipad {
    padding-top: 1rem;
  }
}
.icon-center-archive {
  z-index: 1;
  /* display: flex; */
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  font-size: small;
}
.hp-logo-nbt{
  max-height:85px;
}


.hp-btn-loggedin{
  justify-content: end;
  display: flex;
}
.center-nbt-icon{
  display: flex;
  justify-content: end;
}
.homepage-nav-mob{
  display:none;
}
.homepage-nav-desktop{
  display:none
}
@media only screen and (max-width: 700px) {
  .homepage-nav {
    top: 60px !important;
    height:51px;
  }
  .homepage-nav-mob{
    height:51px;
    display:block;
  }
  .hp-logo-nbt{
    max-height:58px;
  }
  .center-nbt-icon{
    justify-content: start;
  }
}

@media only screen and (min-width: 701px) {
  .homepage-nav {
    top: 97px !important;
    height:57px;
  }

  .homepage-nav-desktop{
      top: 97px !important;
    height:57px;
      display:block
  }
  .hp-logo-nbt{
    max-height:85px;
  }
  .center-nbt-icon{
    justify-content: end;
  }
  
}
.hp_cta_container_mob{
  width: 100%;
  justify-content: center;
  margin-top: 7rem
}
@media only screen and (max-width:375px) {
  .hp_cta_container_mob{
    margin-top: 4rem
  }
}