.MuiListItemIcon-root {
  color: "#2b78a2" !important;
}
.MuiIcon-root {
  color: #2b78a2 !important;
}

@media only screen and (min-width: 1100px) {
  .padding-large-scrn {
    padding-left: 62rem;
  }
}
.MuiAppBar-colorPrimary {
  background-color: #2b78a2;
}
.MuiToolbar-regular {
  justify-content: space-evenly !important;
}

.text-icon-color {
  color: #2b78a2;
  cursor: pointer;
}
.date-border {
  border: none !important;
}

.fabButton {
  position: "absolute";
  zindex: 1;

  left: 0;
  right: 0;
  margin: "0 auto";
}
.search-barwidth {
  width: "-webkit-fill-available" !important;
  top: "61px" !important;
  margin-left: 0px !important;
}
.MuiPopover-paper {
  width: 40rem !important;
  top: 61px !important;
  background-color: #f2f2f3 !important;
  display: inline-table !important;
}

.MuiTypography-body1 {
  font-weight: 500 !important;
}
.MuiRadio-root {
  color: #2b78a2 !important;
}

.MuiTypography-body1 {
  font-size: 12px !important;
}
/* .form-control {
  border: 1px solid #757373;
} */
/* .MuiList-padding {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
} */
.MuiTypography-body1 {
  font-weight: 600 !important;
  font-size: 12px !important;
}
/* .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
  top: 119px !important;
} */
.MuiMenu-paper {
  left: 0px !important;
}
.carousel-control-prev-icon {
  color: #2b78a2 !important;
  background-image: url("https://img.icons8.com/ios-glyphs/60/4a90e2/less-than.png") !important;
}
.carousel-control-next-icon {
  background-image: url("https://img.icons8.com/ios-glyphs/60/4a90e2/more-than.png") !important;
}

/* .nav-item {
  background-color: blue !;
} */
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: white !important;
  background-color: #2b78a2 !important;
  border-color: black;
  border-bottom: none !important;
}
.mob-only {
  display: none !important;
}
@media only screen and (max-width: 700px) {
  .mob-only {
    display: block !important;
  }
}
.desktop-screen-only {
  display: none !important;
}

@media only screen and (min-width: 1366px) {
  .desktop-screen-only {
    display: block !important;
  }
}

.desktop-only {
  display: none !important;
}
@media only screen and (min-width: 1366px) {
  .desktop-only {
    display: block !important;
    /* z-index: 10000; */
  }
}

.desktop-icon-name {
  font-size: 10px;
  font-weight: 600;
  text-align: center;
}
.buttonfixed1 {
  position: fixed;
  top: 25rem;
  margin-top: 1rem;
  cursor: pointer;
  font-size: small;
  left: 12vw;
  z-index: 99999999999999999;
  background-color: #717374;

  transition: background-color 0.3s;
  cursor: pointer;
  vertical-align: middle;

  border: none;
  color: white;

  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  max-width: 40px !important;
  min-height: 6rem;
}
.activebuttonfixed1 {
  position: fixed;
  top: 25rem;
  margin-top: 1rem;
  cursor: pointer;
  font-size: small;
  left: 12vw;
  z-index: 99999999999999999;
  background-color: #2b77a2;

  transition: background-color 0.3s;
  cursor: pointer;
  vertical-align: middle;

  border: none;
  color: white;

  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  max-width: 40px !important;
  min-height: 6rem;
}
/* .button-fixed1:focus {
  background-color: #2b78a2;
} */
.button-fixed-text {
  writing-mode: vertical-rl;
  margin: 0px;
}
.buttonfixed2 {
  position: fixed;
  top: 16.5rem;
  font-size: small;
  margin-bottom: 1rem;
  cursor: pointer;
  left: 12vw;
  z-index: 99999999999999999;
  background-color: #717374;
  transition: background-color 0.3s;
  cursor: pointer;
  vertical-align: middle;
  border: none;
  color: white;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  max-width: 40px !important;
  min-height: 9rem;
}
.activebuttonfixed2 {
  position: fixed;
  top: 16.5rem;
  font-size: small;
  margin-bottom: 1rem;
  cursor: pointer;
  left: 12vw;
  z-index: 99999999999999999;
  background-color: #2b77a2;
  transition: background-color 0.3s;
  cursor: pointer;
  vertical-align: middle;
  border: none;
  color: white;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  max-width: 40px !important;
  min-height: 9rem;
}

.MuiDrawer-paper {
  z-index: 1 !important;
  background-color: #f8f9fa !important;
}
@media only screen and (max-width: 700px) {
  .MuiDrawer-paper {
    background-color: white !important;
  }
}
.button-fixed-5-2 {
  position: fixed;
  bottom: 40%;
  font-size: small;
  margin-bottom: 1rem;
  cursor: pointer;
  /* transform: translate3d(0, 0, 0); */
  left: 0.1vw;
  z-index: 200;
  /* border-radius: 50% !important; */

  background-color: #2b78a2;

  transition: background-color 0.3s;
  cursor: pointer;
  /* vertical-align: middle; */

  border: none;
  color: white;

  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  max-width: 25px !important;
  min-height: 7rem;
  transition: 0.3s all ease;
}
.button-fixed-text-5 {
  display: block;
  color: white;
  transform: rotate(-90deg);
  margin-top: 35px;
}

.button-fixed3 {
  position: fixed;
  bottom: 42%;
  font-size: small;
  margin-bottom: 1rem;
  cursor: pointer;
  z-index: 1;
  right: 0.2vw;

  transition: background-color 0.3s;
  cursor: pointer;
  vertical-align: middle;

  border: none;
  color: white;

  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  max-width: 27px;
  min-height: 7rem;
  transition: 0.3s all ease;
}
.button-fixed4 {
  position: fixed;
  top: 18vh;
  font-size: small;
  margin-bottom: 1rem;
  cursor: pointer;
  z-index: 200 !important;
  right: 0.7vw;
  z-index: 200 !important;
  /* border-radius: 50% !important; */

  cursor: pointer;
  vertical-align: middle;

  border: none;
  color: #2b77a2;

  /* max-width: 25px !important; */
  min-height: 7rem;
  transition: 0.3s all ease;
}
.button-fixed-5 {
  position: fixed;
  top: 8%;
  font-size: small;
  margin-bottom: 1rem;
  cursor: pointer;

  left: 10vw;
  z-index: 99999999999999999;
  border-radius: 50% !important;

  background-color: #2b77a2;

  transition: background-color 0.3s;
  cursor: pointer;
  /* vertical-align: middle;

  border: none;
  color: white;

  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  max-width: 40px !important;
  min-height: 7rem; */
  transition: 0.3s all ease;
}
.button-fixed-5-1 {
  /* position: fixed; */
  bottom: 40%;
  font-size: small;
  margin-bottom: 1rem;
  cursor: pointer;

  left: -10px;
  /* z-index: 99999999999999999; */
  /* border-radius: 50% !important; */

  background-color: #2b78a2;

  transition: background-color 0.3s;
  cursor: pointer;
  /* vertical-align: middle; */

  border: none;
  color: white;

  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  max-width: 40px !important;
  min-height: 7rem;
  transition: 0.3s all ease;
}

.button-fixed-text3 {
  /* writing-mode: tb-rl; */
  display: block;
  color: white;
  transform: rotate(-90deg);
  /* margin-top: 10px; */
  margin-top: 35px !important;
}
.button-fixed-text-3 {
  display: block;
  transform: rotate(-90deg);
  margin-top: 4rem !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
.logo-center {
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  left: 0;
  right: 0;
  margin: auto;
  align-self: center;
}
.logo-img {
  max-width: 150px;
  max-height: 50px;
}
/* @media only screen and (max-width: 700px) {
  .logo-center {
    z-index: 1;
    display: flex;
    padding-left: 5vw;
    left: 0;
    right: 0;
    margin: auto;
  }
} */
@media only screen and (max-width: 1024px) {
  .logo-center {
    z-index: 1;
    display: flex;
    padding-left: 0vw;
    /* justify-content: flex-start !important; */
    left: 0;
    right: 0;
    margin: auto;
  }
  .button-fixed-5-2,
  .button-fixed4,
  .button-fixed3 {
    max-width: 20px !important;
  }
}
@media only screen and (max-width: 700px) {
  .logo-center {
    z-index: 1 !important;
    display: flex !important;
    justify-content: center !important;
    left: 0 !important;
    right: 0 !important;
    padding-right: 0.7rem !important;
  }
}
@media only screen and (min-width: 1300px) {
  .logo-center {
    justify-content: flex-end !important;
  }
}
.line-height {
  line-height: 15px;
  font-size: 12px;
}

.form-control3 {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #222121 !important;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 2.5rem;
}
.arrow-font {
  font-size: 24px !important;
}
.icon-bar-size {
  font-size: 24px !important;
}
.start-date {
  background-color: rgb(43, 120, 162);
  color: white;
  height: 27px;
  margin-left: 19px;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.end-date {
  background-color: rgb(43, 120, 162);
  color: white;
  height: 27px;
  margin-left: 27px;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.menu-name {
  padding-left: 15px;
  font-size: 14px;
  color: black;
  margin-bottom: auto;
}
.fDQzft {
  height: 45px !important;
}
.eeYQnr {
  height: 45px !important;
}

@media (min-width: 1900px) {
  .feather-29 {
    width: 26px;
    height: 26px;
  }
}
@media (max-width: 1000px) {
  .eeYQnr {
    font-size: 16px !important;
    padding-left: 5px !important;
  }
  .jxAUMY {
    font-size: 10px !important;
    padding-left: 5px !important;
  }
}
@media (min-width: 1900px) {
  .eeYQnr {
    font-size: 18px !important;
    padding-left: 5rem !important;
  }
  .jxAUMY {
    font-size: 18px !important;
    height: 90px !important;
  }
}

@media (min-width: 1900px) {
  .button-fixed2 {
    font-size: 20px !important;
    top: 30rem !important;
    width: 2vw !important;
  }
  .line-height {
    line-height: normal !important;
    font-size: 14px !important;
  }
  .button-fixed1 {
    font-size: 20px !important;
    top: 43rem !important;

    width: 2vw !important;
  }
  .button-fixed-5-1 {
    font-size: 20px !important;
    width: 2vw !important;
  }

  .button-fixed-text {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }

  /* .button-fixed-text-5 {
    font-size: 20px !important;
    padding-top: 30px !important;
    padding-bottom: 30px !important;
    padding-left: 10px !important;
  } */

  .button-fixed-5 {
    font-size: 20px !important;
    width: 2vw !important;
  }
}
*:focus {
  outline: 0 !important;
}
@media (max-width: 1100px) {
  .line-height {
    line-height: 12px;
  }
}
.popular-card-width {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.25rem;
}
@media (max-width: 768px) {
  .popular-card-width {
    padding-left: 0.2rem;
    padding-right: 0.2rem;
    padding-bottom: 0.25rem;
  }
  .line-height {
    font-size: 10px;
    line-height: 12px;
  }
}

#cursor-pointer {
  cursor: pointer !important;
}
.cursor-none {
  cursor: default;
}
#nav-bar {
  top: 60px !important;
}
@media (min-width: 1900px) {
  #nav-bar {
    height: 60px !important;
  }
}
.tab-menu {
  display: none;
}
@media (max-width: 1024px) {
  .tab-menu {
    display: block;
  }
}
.react-datepicker__month-container {
  border-radius: 4px !important;
  border: 1px solid #2b78a2 !important;
}
.react-datepicker__header {
  background-color: #2b78a2 !important;
  border: none !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: white !important;
}
.react-datepicker__day-name {
  color: white !important;
}

.react-datepicker__navigation-icon::before,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-color: white !important;
}
.react-datepicker {
  border: 0px !important;
}
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  border-bottom-color: #2b78a2;
}
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  border-bottom-color: #2b78a2 !important;
}
.img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.7;
  height: 100%;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
}
.card-calender {
  margin-right: 20px;
  cursor: pointer;
  max-width: calc(100% / 7 - 20px);
  margin-bottom: 20px;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.card-calender:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
/* .popular-drawer-scroll::-webkit-scrollbar {
  width: 0.5vw;
  height: 8px;
}

.popular-drawer-scroll::-webkit-scrollbar-track {
  background: none;
}

.popular-drawer-scroll::-webkit-scrollbar-thumb {
  background-color: rgb(174, 174, 175);
}
.popular-drawer {
  overflow: scroll;
  height: 1000px;
} */
/* .dropdown-menus {
  top: 38px !important;
  left: -56 !important;
} */
#nav-desktop {
  top: 100px;
  z-index: 99;
}
#nav-mob {
  z-index: 999;
}
.most-read {
  color: black;
  font-size: smaller;
  font-weight: normal;
}
.popular-card-header {
  background-color: #f5f5f5 !important;
  padding: 0px !important;
  border: none;
  margin-left: 4px;
}
.head-ax {
  position: fixed;
  top: 0;
  z-index: 99;
  width: -webkit-fill-available;
  width: -moz-available;
  border-bottom: 3px solid rgb(206, 5, 5);
}
@media only screen and (max-height: 450px) {
  #nav-desktop {
    z-index: 99;
  }
  .head-ax {
    z-index: 99;
  }
}
.popular-name-pub {
  font-size: 14px;
}
.popular-date-pub {
  font-size: 13px;
  color: #fb1010;
}
.close-popular {
  width: 1.3em !important;
}
.close-popular:hover {
  width: 1.4em !important;
  color: #a70606;
}
@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */
  .ie-leftbar {
    max-width: 25px !important;
  }
  .head-ax {
    position: -webkit-sticky;
    position: sticky;
    position: fixed;
    width: 100%;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 99;
  }
  SidebarNav {
    background-color: white !important;
  }
}

.close-date-modal {
  width: 1.3em !important;
  cursor: pointer;
}
.close-date-modal:hover {
  width: 1.4em !important;
  color: #a70606;
}
.epaper-logo {
  display: block;
}

@media screen and (max-width: 1132px) {
  .epaper-logo {
    display: none !important;
  }
}

.introjs-tooltiptext,
.introjs-bullets {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.introjs-button {
  box-sizing: content-box !important;
  position: relative !important;
  overflow: visible !important;
  display: inline-block !important;
  padding: 0.2rem 0.9rem !important;
  /* border: 1px solid #BDBDBD !important; */
  text-decoration: none !important;
  text-shadow: 1px 1px 0 #be2819 !important;
  font-size: 12px !important;
  color: #ffffff !important;
  white-space: nowrap !important;
  cursor: pointer !important;
  outline: none !important;
  background-color: #be2819 !important;
  border-radius: 0.2em !important;
  zoom: 1 !important;
}
/* .introjs-disabled {
  color: #9e9e9e !important;
  background-color: #f4f4f4 !important;
} */
.introjs-fixedTooltip {
  box-shadow: rgb(33 33 33 / 80%) 0px 0px 0px 5000px !important;
}
.modal_container .modal-content {
  background: #fff !important;
}
.modal_container .form-group {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}
SidebarNav {
  top: 148px;
}

/* @media screen and (max-width: 700px) {
  .modal_container {
    max-height: 90%;
    top: auto;
  }
} */

.sticky-cross-toc {
  position: fixed;
  top: 150px;
  background-color: #fff;
  min-width: 230px;
  width: 22vw;
  display: flex;
  justify-content: space-between;
  font-family: serif;
  color: black;
  z-index: 999;
  transition: 350ms;
}

@media only screen and (max-width: 700px) {
  SidebarNav {
    top: 0px;
  }
  DropdownLink {
    padding-left: 0.2rem;
  }
  .sticky-cross-toc {
    position: fixed;

    background-color: #fff;
    min-width: 230px;
    width: 22vw;
    display: flex;
    justify-content: space-between;
    font-family: serif;
    color: black;
    z-index: 99999;
  }
}
time.icon {
  top: -1;
  font-size: 9px; /* change icon size */
  display: block;
  position: relative;
  width: 5em;
  height: 31px;
  background-color: #fff;
  border-radius: 4px;
  /* box-shadow: 0 1px 0 #bdbdbd, 0 2px 0 #fff, 0 3px 0 #bdbdbd, 0 4px 0 #fff, */
  box-shadow: 0 0px 0 #bdbdbd, 0 0px 0 #fff, 0 3px 0 #bdbdbd, 0 0px 0 #fff,
    0 0px 0 #bdbdbd, 0 0 0 1px #bdbdbd;

  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-transform: rotate(0deg) skewY(0deg);
  -webkit-transform-origin: 50% 10%;
  transform-origin: 50% 10%;
}
@media only screen and (min-width: 701px) {
  time.icon {
    width: 7em;
  }
}

time.icon * {
  display: block;
  width: 100%;
  font-size: 1em;
  font-weight: bold;
  font-style: normal;
  text-align: center;
}

time.icon strong {
  position: absolute;
  top: 0;
  /* padding: 0.4em 0; */
  color: #fff;
  /* box-shadow: 0 2px 0 #fd9f1b; */
}

time.icon em {
  position: absolute;
  bottom: 0.3em;
  color: #fd9f1b;
}

time.icon span {
  width: 100%;
  font-size: 13px;
  color: #2f2f2f;
  padding-top: 14px;
}

time.icon:hover,
time.icon:focus {
  -webkit-animation: swing 0.6s ease-out;
  animation: swing 0.6s ease-out;
}

@-webkit-keyframes swing {
  0% {
    -webkit-transform: rotate(0deg) skewY(0deg);
  }
  20% {
    -webkit-transform: rotate(12deg) skewY(4deg);
  }
  60% {
    -webkit-transform: rotate(-9deg) skewY(-3deg);
  }
  80% {
    -webkit-transform: rotate(6deg) skewY(-2deg);
  }
  100% {
    -webkit-transform: rotate(0deg) skewY(0deg);
  }
}

@keyframes swing {
  0% {
    transform: rotate(0deg) skewY(0deg);
  }
  20% {
    transform: rotate(12deg) skewY(4deg);
  }
  60% {
    transform: rotate(-9deg) skewY(-3deg);
  }
  80% {
    transform: rotate(6deg) skewY(-2deg);
  }
  100% {
    transform: rotate(0deg) skewY(0deg);
  }
}
.mob-ad-only-header {
  display: none;
}
.mob-header {
  position: fixed;
  top: 0;
  z-index: 999;
  width: -webkit-fill-available;
  width: -moz-fill-available;
  height: 64px;
  border-radius: 0px;
  justify-content: center;
  text-align: center;
}
@media (max-width: 700px) {
  .mob-ad-only {
    display: flex;
  }
  .mob-ad-only-header {
    display: flex;
    background-color: white !important;
    width: -webkit-fill-available;
    width: -moz-available;
  }
}
.btn-login-tbpar {
  width: 7rem;
  height: 1.8rem;
  border-radius: 3.5px;
  font-size: 14px;
  text-decoration: none;
  border-radius: 3.5px;
  cursor: pointer;
  color: #F2923D;
  margin-right: 5px;
  background-color: white;
  border: 2px solid #F2923D;
  margin-top:10px;
}
.btn-trial-tbpar{
  width: 7rem;
  height: 1.8rem;
  border-radius: 3.5px;
  font-size: 14px;
  text-decoration: none;
  border-radius: 3.5px;
  cursor: pointer;
  color: #F2923D;
  margin-right: 5px;
  background-color: white;
  border: 2px solid #F2923D;
  margin-top:10px;
}
@media only screen and (max-width: 700px) {
  .btn-login-tbpar {
    width: 3rem;
    height: 1.8rem;
    border-radius: 3.5px;
    font-size: 14px;
    text-decoration: none;
    border-radius: 3.5px;
    cursor: pointer;
    color: #F2923D;
    margin-right: 5px;
    background-color: white;
    border:none;
    margin-top:0px;
  }
  
}
.btn-login-hpage{
  margin-top:28px;
  width: 5rem;
  height: 1.8rem;
  border-radius: 3.5px;
  font-size: 14px;
  text-decoration: none;
  border-radius: 3.5px;
  cursor: pointer;
  color: #F2923D;
  margin-right: 5px;
  background-color: white;
  border: 2px solid #F2923D;
  margin-bottom: 3px;
}
@media only screen and (max-width: 700px) {
  .btn-login-hpage{
    margin-top:5px;
    border: none !important;

  }
}