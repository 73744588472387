.dropdown-archive {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  background-color: #f9f9f9;
  padding: 7.5px 7.5px 3.75px;
  border: 1px solid #ddd;
  border-radius: 2.5px;
  font-size: 1.33rem;
  min-height: 44px;
  overflow: hidden;
  cursor: pointer;
  color: black;
}
.dropdown-archive:focus {
  background-color: #f9f9f9 !important;
  color: black !important;
  box-shadow: none !important;
}
.dropdown-archive:hover {
  background-color: #f9f9f9 !important;
  color: black !important;
  box-shadow: none !important;
}
.archive_wrapper {
  width: 98%;
  margin: auto;
}

.month_item {
  padding: 1px !important;
  margin-bottom: 20px;
}
.month_item:hover {
  background: lightgray;
  cursor: pointer;
}
.img_card_archive {
  margin-bottom: 1rem;
  position: relative;
  border: 1px solid black;
  overflow: hidden;
  /* max-height: "150px"; */
}
.img_card_archive img {
  object-fit: contain;
  object-position: center;
}

.card_footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 31px;
  font-family: "Segoe UI";
  font-size: 1.2rem;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  text-align: center;
  /* opacity: .6; */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img_card_archive:hover .card_footer {
  background: rgba(236, 137, 29, 0.7);
}
.img_card_archive:hover {
  border: 1px solid #ec891d;
  /* cursor: pointer; */
}

.border-card-ar {
  position: absolute;
  /* border: solid 1px#747474; */
  border-radius: 2px;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  box-sizing: border-box;
}
.img_card:hover .border {
  border: 1px solid #ec891d;
  background: rgba(76, 163, 228, 0.2);
}
.year_list {
  position: relative;
  height: 40px;
  z-index: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  border: solid 1px #ccc;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  font-size: 16pt;
  overflow: hidden;
  white-space: nowrap;
}

.data-content {
  padding: 4px 25px;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  background-color: white;
}
.year_list ul {
  position: relative;
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
}
.year_list li {
  display: inline-block;
  padding: 0 10px;
  text-align: center;
  color: #535353;
  cursor: pointer;
}
.arrow {
  position: absolute;
  width: 20px;
  height: 100%;
  top: 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  z-index: 10;
  cursor: pointer;
}
.arrow.arrow-left {
  left: 0;
}
.arrow.arrow-right {
  right: 0;
}
.year_list .top-shadow {
  position: absolute;
  top: 0;
  height: 1px;
  width: 100%;
  background-color: #ddd;
  -webkit-box-shadow: 0 0 4px 1px #ddd;
  box-shadow: 0 0 4px 1px #ddd;
}
.banner_ad {
  width: 100%;
  height: 120px;
  background: red;
}
@media only screen and (max-width: 360px) {
  .img_card {
    width: 100% !important;
  }
}
.toi-label-span-archive {
  display: table;
  padding: 2px 10px;
  border: 1px solid #dddddd;
  border-width: 1px 1px 0 1px;
  border-radius: 0.33em 0.33em 0 0;
  font-size: 1rem !important;
  margin-bottom: 0;
}
.chaoice-inner-archive {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  background-color: #f9f9f9;
  padding: 7.5px 7.5px 3.75px;
  border: 1px solid #ddd;
  border-radius: 2.5px;
  font-size: 1rem;
  min-height: 31px;
  overflow: hidden;
  cursor: pointer;
  color: black;
}
@media (max-width: 1024px) {
  .chaoice-inner-archive {
    font-size: 10px;
  }
}
@media (min-width: 701px) {
  .archive-wrapper {
    margin-top: 60px !important;
  }
}
.chaoice-inner-archive:focus {
  background-color: #f9f9f9 !important;
  color: black !important;
  box-shadow: none !important;
}
.chaoice-inner-archive:hover {
  background-color: #f9f9f9 !important;
  color: black !important;
  box-shadow: none !important;
}

.mymodal-archive {
  position: absolute;
  top: 50%;
  left: 50%;
  right: 30%;
  transform: translate(-50%, -50%);
  min-width: 540px;
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  /* border-radius: 22px; */
  outline: none;
  width: 100%;
  height: 100%;
  padding: 0px 20px 20px 20px;
  z-index: 9999999;
}
.close-archive-modal {
  text-align: right;
  cursor: pointer;
  color: red;
}
.close-archive-modal:hover {
  text-align: right;
  cursor: pointer;
  color: red;
}
.icon-bar-size-archive {
  font-size: 24px;
}
.icon-bar-size-archive:hover {
  font-size: 30px !important;
}
.dropdown-archive-menu {
  width: -webkit-fill-available !important;
}
.myoverlay-mob-archive {
  position: fixed;
  overflow: auto !important;
  top: 0%;
  left: 0;
  right: 0;
  bottom: 48.5px;
  background-color: rgb(255, 255, 255);
  z-index: 99999;
}

.year_lists {
  position: relative;
  height: 30px;
  z-index: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  top: 0px;
  font-size: 11pt;
  /* overflow: hidden; */
  white-space: nowrap;
}

.data-contents {
  padding: 0px 5px;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  background-color: white;
}
.year_lists ul {
  position: relative;
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
}
.year_lists li {
  display: inline-block;
  padding: 0 10px;
  text-align: center;
  color: #535353;
  cursor: pointer;
  color: red;
  border: 1px solid lightgrey;
  border-radius: 4px;
  margin-right: 5px;
}
.arrows {
  position: absolute;
  width: 20px;
  height: 100%;
  top: -8px;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  z-index: 10;
  cursor: pointer;
}
.arrows.arrow-left {
  left: 0;
}
.arrows.arrow-right {
  right: 0;
}

.icon-center-archive {
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  font-size: small;
}

.image_card_archive {
  margin-bottom: 1rem;
  position: relative;
  /* border: 1px solid black; */
  overflow: hidden;
  /* max-height: "150px"; */
}
.image_card_archive img {
  object-fit: contain;
  object-position: center;
}
.card_footer-arch {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 28px;
  font-family: "Segoe UI";
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  /* opacity: .6; */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: "0px 0px 3px 3px";
  padding: " 0px 4px";
  background-color: #1b1b1ad1;
}
.image_card_archive:hover .card_footer-arch {
  background: black;
}
.image_card_archive:hover {
  border: 1px solid black;
  /* cursor: pointer; */
}

.date-font-archive {
  font-size: medium;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media only screen and (max-width: 1024px) {
  .date-font-archive {
    font-size: small;
  }
}
.month_items {
  padding: 0px 4px !important;
  margin-bottom: 20px;
  font-size: 10px !important;
}

.month_items-ar {
  padding: 0px 6px !important;
  margin-bottom: 20px;
  font-size: 12px !important;
  margin-right: 3px !important;
  width: 48px;
  text-align: center;
}
.month_items-ar:hover {
  color: black !important;
}
.icon-choice {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  background-color: #ffffff;
  border: none;
  overflow: hidden;
  cursor: pointer;
  padding-top: 0px;
}
.icon-choice:focus {
  background-color: white !important;
  box-shadow: none !important;
}
.icon-choice:hover {
  background-color: white !important;
  box-shadow: none !important;
}
.arc-container {
  margin: 120px auto auto auto;
}
.icon-18 {
  width: 20px;
  height: 20px;
}
.right-ar-year {
  padding: 0px 5px;
  border: 1px solid grey;
  margin-right: 5px;
  border-radius: 3px;
}
.text-align-left {
  text-align: left !important;
}

@media only screen and (max-width: 700px) {
  .archive-nav {
    top: 60px !important;
  }
}
@media only screen and (min-width: 701px) {
  .archive-nav {
    top: 103px !important;
  }
}
.month_items-archive {
  padding: 0px 4px !important;
  margin-bottom: 20px;
  font-size: 10px !important;
}
@media only screen and (min-width: 1200px) {
  .month_items-archive {
    font-size: 12px !important;
  }
}
.chaoice-inner-archive-right {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  background-color: white;
  padding: 10px 12px;
  border: none;
  border-radius: 2.5px;
  font-size: 1rem;
  min-height: 31px;
  overflow: hidden;
  cursor: pointer;
  color: grey;
}
.chaoice-inner-archive-right:focus {
  background-color: #f9f9f9 !important;
  color: black !important;
  box-shadow: none !important;
}
.chaoice-inner-archive-right:hover {
  background-color: white !important;
  color: black !important;
  box-shadow: none !important;
}
.font-sanserf {
  font-family: sans-serif;
}
.only-big-scrn-arc {
  display: none;
}
.only-big-scrn-arc-dates {
  display: none;
}
.only-samll-scrn-filtr {
  display: none;
}
@media only screen and (min-width: 1024px) {
  .only-big-scrn-arc {
    display: flex;
  }
  .only-big-scrn-arc-dates {
    display: block;
  }
}
.only-small-scrn-arc {
  display: none;
}
@media only screen and (max-width: 1023px) {
  .only-small-scrn-arc {
    display: block;
  }
  .only-samll-scrn-filtr {
    display: block;
  }
}
